<template>
  <div>
    <div ref="navBarDiv" class="navBarContainer">
      <ViewerLocalNavBar/>
    </div>
    <div ref="titleDiv" class="titleContainer">
      <b-badge class="d-none d-sm-block" variant="info" show>Local DICOM</b-badge>
    </div>
    <div :class="iFrameContainer">
      <iframe :style="iFrameStyle" :src="viewerUrl">
        <html><head></head><body style="background: black;"></body></html>
      </iframe>
    </div>
  </div>
</template>

<script>
import ViewerLocalNavBar from './ViewerLocalNavBar.vue'

export default {
  name: 'viewerLocal',
  components: {
    ViewerLocalNavBar,
  },
  data() {
    return {
      iFrameHeight: window.innerHeight
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize()
  },
  computed: {
    iFrameContainer() {
      return this.$configs.localViewerModel
    },
    iFrameStyle() {
      return "border: 1px solid #837E7C; top: 0px; left: 0px; height: "+this.iFrameHeight+"px; width: 100%;"
    },
    viewerUrl() {
      var viewerUrl = this.$configs.ohifViewerBaseUrl + '/local?' + Date.now()
      return viewerUrl
    }
  },
  methods: {
    handleResize() {
      this.$log.debug("windowH="+window.innerHeight+" divH="+this.$refs.navBarDiv.clientHeight+" titleH="+this.$refs.titleDiv.clientHeight)
      this.iFrameHeight = window.innerHeight - this.$refs.titleDiv.clientHeight - this.$refs.navBarDiv.clientHeight - 20;
    }
  }
};
</script>
<style scoped>
.navBarContainer {
  z-index: 2;
}
.titleContainer {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}
.ohif {
  position: relative;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.meddream {
  position: relative;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
</style>